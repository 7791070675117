<template>
  <b-card style="width: 500px">
    <template slot="header">
      <div class="d-flex justify-content-between">
        <div><strong>BetaSafe AuthCtl Automatic Enrolment</strong></div>
        <div>{{tenant.name}}</div>
      </div>
    </template>
    <b-card-text>
      <b-overlay :show="busy">
        <p>This account is eligible for automatic enrolment.  Please confirm your details below, and you will receive an email
          with your account password:</p>

        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>Name</b-input-group-prepend>
          <b-input type="text" readonly />
        </b-input-group>

        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>Email Address</b-input-group-prepend>
          <b-input type="text" readonly />
        </b-input-group>

        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>Username</b-input-group-prepend>
          <b-input type="text" readonly />
        </b-input-group>

        <b-checkbox class="mb-2" v-model="accept">I confirm that the above information is correct.</b-checkbox>
        <b-btn variant="primary" @click="enrol" :disabled="!canEnrol"><icon-text icon="check">Enrol</icon-text></b-btn>
      </b-overlay>
    </b-card-text>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'
import { mapGetters } from 'vuex'

export default {
  components: { IconText },
  data: () => ({
    busy: false,
    enrolmentToken: null,
    enrolmentData: null,
    accept: false
  }),
  computed: {
    ...mapGetters({
      tenant: 'tenant/tenant'
    }),
    canEnrol () {
      return this.accept && !this.busy
    }
  },
  methods: {
    enrol () {
      this.busy = true
      //
    }
  },
  mounted () {
    this.enrolmentToken = window.location.hash.substr(1)
  }
}
</script>
